body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --primary-color: #ffffff;
  --secondary-color: #142240;
  --white-color: #fff;
  --blue: #3173ad;
  --green: #4ea64e;
  --layercategorytext: #282726;
  --hoverbg: #e3a09b;
  --scroll: #d8e0eb;
  --red: #ff0000;
}
