@import 'variables';

h1,h2,h3 {
    font-style: normal;
    font-weight: 700 !important;
    color: #2D2F3A;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: -18px 0px;
    font-family: 'Manrope', sans-serif;
    
}
h1 {
    font-size: 32px !important;
    line-height: 80px;
    letter-spacing: 0px;
}
h2 {
    font-size: 24px !important;
    line-height: 64px;    
}
h3 {
    font-size: 20px;
    line-height: 48px;
}
h4{
font-style: normal;
font-weight: 600 !important;
font-size: 20px !important;
line-height: 27px;
color: #2D2F3A;
}
label {
    position: inherit;
    left: 22.05%;
    right: 74.3%;
    top: 10.71%;
    bottom: 86.01%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #2D2F3A;
}
select {
    box-sizing: border-box;
    height: 48px;
    left: 0px;
    top: 194.57px;
    background: #FFFFFF;
    border: 1px solid #CBC9C9;
    border-radius: 4px;
}
.btn-default {
    min-width: 135px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 11px 22px;
    gap: 10px;
    width: fit-content;
    height: 48px;
    background-color: $primary-color !important;
    border-radius: 40px;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 40px !important;
    border: none !important;
    &:hover{
        background-color:#1F3B8C !important;
    }
}
.btn-outline {
    min-width: 135px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 11px 22px;
    gap: 10px;
    height: 48px;
    background: #FFFFFF !important;
    color: $primary-color !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    border: 1px solid $primary-color !important;
    border-radius: 40px !important;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;
    &:hover{
        background: #E2EAFF !important;
        color: #1F3B8C;
    }
    &:focus{
        color:#3D5CB8;
        background: #E2EAFF;
        box-shadow: 0px 8px 16px rgba(61, 141, 219, 0.2);
    }
}
.text-input {
    box-sizing: border-box;
    height: 48px;
    left: 209.5px;
    top: 83.48px;
    background: #FFFFFF;
    border: 1px solid #CBC9C9 !important;
    border-radius: 4px !important;
    &:hover {
        border: 1px solid #5A5C68 !important;
    }
    &:active{
        border: 1px solid #CBC9C9 !important;
    }
    &:focus{
        border: 1.5px solid #3D5CB8 !important;
        box-shadow: none !important;
    }
}
.error-message{
    font-weight: 400;
    font-size: 12px;
    color: #EB5757;
    line-height: 18px;
    width: 208px;
    height: 18px;
    font-family: 'Manrope';
    font-style: normal;
    padding-top: 5px;
    &::before{
        content: "i";
        background-color: #EB5757;
        color: #fff;
        padding: 1px 8px;
        border-radius: 50%;
        margin-right: 4px;
    }
}
