.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  padding: 1.625rem !important;
}
.text-muted a.text-muted {
  --bs-text-opacity: 1;
  color: #a1acb8 !important;
  text-decoration: none;
}
.breadcrumb-item.active{
  color: #566a7f !important;
  font-weight: 700;
}
.table:not(.table-dark) th {
  color: #566a7f;
}
.table th {
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 1px;
}
.table > :not(caption) > * > * {
  padding: 0.625rem 1.25rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
td{
  font-size: 0.75rem ;
  font-weight: 400;
  color: #697a8d ;
}

