@font-face {
  font-family: "manrope-bold";
  src: local("ManropeBold"), url("./fonts/Manrope-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "manrope-extra-bold";
  src: local("manrope-extra-bold"),
    url("./fonts/Manrope-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "manrope-extra-light";
  src: local("manrope-extra-light"),
    url("./fonts/Manrope-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "manrope-light";
  src: local("manrope-light"),
    url("./fonts/Manrope-Light.ttf") format("truetype");
}

@font-face {
  font-family: "manrope-medium";
  src: local("manrope-medium"),
    url("./fonts/Manrope-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "manrope-regular";
  src: local("manrope-regular"),
    url("./fonts/Manrope-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "manrope-semi-bold";
  src: local("manrope-semi-bold"),
    url("./fonts/Manrope-SemiBold.ttf") format("truetype");
}
